import {FC, Fragment, useState} from 'react'
import {AgendaLeadTypeMap, ApplicationAgendaItemInterface} from '@/interfaces/user_types'
import {
    Box,
    Button,
    Card,
    CardHeader,
    Chip,
    FormControlLabel,
    IconButton,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import useGenevaUser from '@/hooks/useGenevaUser'
import {formatDate, formatDateAsISO, isNullOrUndeclared} from '@/utils/formatter'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import {HtmlTooltip} from '@/components/widgets/HTMLTooltip'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import GenevaIconAvatar from '@/components/widgets/GenevaIconAvatar'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import {countLeadOpenTasks} from '@/lib/api/geneva/lead'
import {handleOpenLinkInNewTab, isNumber} from '@/utils/general'
import {LeadSubTaskTypeEnum, LeadTaskInterface} from '@/interfaces/task_types'
import {addBusinessDays} from 'date-fns/addBusinessDays'
import {mutate} from 'swr'
import {useSnackbar} from 'notistack'
import {skeletonRows} from '@/utils/table'
import {getPriorityColor, getPrioritySmallIcon} from '@/components/task/TaskPrioritySmallIcon'
import CakeIcon from '@mui/icons-material/Cake'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import InsightsIcon from '@mui/icons-material/Insights'
import DomainAddIcon from '@mui/icons-material/DomainAdd'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import dynamic from 'next/dynamic'

const UserAgendaItemsModal = dynamic(() => import('@/components/user/UserAgendaItemsModal'), {ssr: false})
const LeadTaskQuickActionModal = dynamic(() => import('@/components/sales/lead/LeadTaskQuickActionModal'), {ssr: false})
const LeadTaskCreateModal = dynamic(() => import('@/components/task/LeadTaskCreateModal'), {ssr: false})

interface UserAgendaCardProps {
    title: string
    boldTitle: boolean
    agendaItems: ApplicationAgendaItemInterface[]
    agendaItemLimit?: number
    reportsItemsCount: number | null
    countTomorrowAgendaItems: number | null
    countTomorrowCallbackAgendaItems: number | null
    agendaDate: Date
    setAgendaDate: Function
    isShowReports: boolean
    setIsShowReports: Function
    showDatePicker: boolean
    isOverdue: boolean
    isLoading: boolean
    isUseLeadTaskQuickActionModal?: boolean
    showIncludeReports?: boolean
    narrowHeader?: boolean
    disableDynamicHeight?: boolean
    showAgendaLeadChips?: boolean
    selectedAgendaLeadTypeId?: number
    setSelectedAgendaLeadTypeId?: Function
}

interface LeadInformation {
    leadId: number
    taskId: number
}

export const getAgendaItemDetails = (agendaItem: ApplicationAgendaItemInterface) => {
    let elements = [<Box>{agendaItem.details}</Box>]
    if (agendaItem.is_meeting_appointment) {
        if (agendaItem.is_confirmed_with_client) {
            elements.unshift(<CheckCircleIcon color="success" sx={{mr: 2}} />)
        } else {
            elements.unshift(<Box>{`[Due] `}</Box>)
        }
    }
    if (agendaItem.is_call_back_task) {
        elements.unshift(<PhoneCallbackIcon color={getPriorityColor(agendaItem.lu_task_priority_id)} sx={{mr: 2}} />)
    } else if (agendaItem.is_task) {
        elements.unshift(getPrioritySmallIcon(agendaItem.lu_task_priority_id, 2))
    }
    if (agendaItem.is_person) {
        elements.unshift(<EmojiPeopleIcon sx={{mr: 2}} />)
    }
    if (agendaItem.is_birthday) {
        elements.unshift(<CakeIcon color="success" sx={{mr: 2}} />)
    }
    if (agendaItem.is_overdue_activation) {
        elements.unshift(<PriorityHighIcon color="error" sx={{mr: 2}} />)
    }
    if (agendaItem.is_care_plan) {
        elements.unshift(<LibraryBooksIcon color="warning" sx={{mr: 2}} />)
    }
    if (agendaItem.is_sla) {
        elements.unshift(<InsightsIcon sx={{mr: 2}} />)
    }
    if (agendaItem.is_supplier_lead) {
        elements.unshift(<DomainAddIcon sx={{mr: 2}} />)
    }
    if (agendaItem.is_no_next_task_lead) {
        elements.unshift(<ErrorOutlineIcon color="error" sx={{mr: 2}} />)
    }
    if (agendaItem.is_package_expiring_lead) {
        elements.unshift(<ErrorOutlineIcon color="error" sx={{mr: 2}} />)
    }
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            {elements.map((element, index) => {
                return <Fragment key={index}>{element}</Fragment>
            })}
        </Box>
    )
}

const getDueTomorrowText = (
    countTomorrowAgendaItems: number,
    countTomorrowCallbackAgendaItems: number | null,
    nextDayString: string,
) => {
    let result = `There are ${countTomorrowAgendaItems} items due ${nextDayString}`
    if (countTomorrowCallbackAgendaItems && countTomorrowCallbackAgendaItems > 0) {
        result += ` (${countTomorrowCallbackAgendaItems} call back task${
            countTomorrowCallbackAgendaItems > 1 ? 's' : ''
        })`
    }
    return result
}

export const UserAgendaCard: FC<UserAgendaCardProps> = ({
    title,
    boldTitle,
    agendaItems,
    agendaItemLimit,
    reportsItemsCount,
    countTomorrowAgendaItems,
    countTomorrowCallbackAgendaItems,
    agendaDate,
    setAgendaDate,
    isShowReports,
    setIsShowReports,
    showDatePicker,
    isOverdue,
    isLoading,
    isUseLeadTaskQuickActionModal = false,
    showIncludeReports = false,
    narrowHeader = false,
    disableDynamicHeight = false,
    showAgendaLeadChips = false,
    selectedAgendaLeadTypeId,
    setSelectedAgendaLeadTypeId,
}) => {
    const {enqueueSnackbar} = useSnackbar()
    const {genevaUser} = useGenevaUser()
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [selectedLeadInformation, setSelectedLeadInformation] = useState<LeadInformation>()
    const [dueDate, setDueDate] = useState<Date>()
    const [luLeadSubTaskTypeId, setLuLeadSubTaskTypeId] = useState<number>()
    const [isTaskCreateModalOpen, setIsTaskCreateModalOpen] = useState<boolean>(false)
    const [isLeadTaskQuickActionModalOpen, setIsLeadTaskQuickActionModalOpen] = useState<boolean>(false)

    const handleAgendaDateChange = async (value: Date | null): Promise<void> => {
        if (isNullOrUndeclared(value)) {
            setAgendaDate(new Date())
        } else {
            setAgendaDate(value)
        }
    }

    const handleHeaderClick = async () => {
        if (genevaUser) {
            const url = `/user/${genevaUser.id}/agenda`
            handleOpenLinkInNewTab(url)
        }
    }

    const handleComplete = async (lead_id: number) => {
        const {data} = await countLeadOpenTasks(lead_id)
        setIsLeadTaskQuickActionModalOpen(false)
        if (isNumber(data.count) && data.count == 0) {
            setLuLeadSubTaskTypeId(LeadSubTaskTypeEnum.LU_LEAD_SUB_TASK_TYPE_SALES_CALL_FOLLOW_UP)
            setDueDate(new Date(formatDateAsISO(addBusinessDays(new Date(), 5))))
            setIsTaskCreateModalOpen(true)
        }
        await mutate(`/api/users/${genevaUser!.id}/agenda?from_date=${formatDateAsISO(agendaDate)}`)
        await mutate(`/api/users/${genevaUser!.id}/overdue_agenda`)
    }

    const handleTaskComplete = async (newTask: LeadTaskInterface) => {
        setLuLeadSubTaskTypeId(undefined)
        setDueDate(undefined)
        setIsTaskCreateModalOpen(false)
        enqueueSnackbar(`Task ${newTask.subject} saved`, {
            variant: 'success',
        })
        await mutate(`/api/users/${genevaUser!.id}/agenda?from_date=${formatDateAsISO(agendaDate)}`)
        await mutate(`/api/users/${genevaUser!.id}/overdue_agenda`)
    }

    const shownAgendaItems = agendaItemLimit ? agendaItems.slice(0, agendaItemLimit) : agendaItems
    const extraAgendaItems =
        agendaItemLimit && agendaItems.length > agendaItemLimit ? agendaItems.slice(agendaItemLimit) : []

    const hasOverdueTasks = () => {
        if (isOverdue) {
            return agendaItems.length > 0
        }
        return false
    }

    const handleClick = (agendaItem: ApplicationAgendaItemInterface) => {
        setIsLeadTaskQuickActionModalOpen(true)
        setSelectedLeadInformation({leadId: agendaItem.lead_id, taskId: agendaItem.task_id})
    }

    let skeletonColumnCount = 2
    if (isOverdue) {
        skeletonColumnCount += 1
    }
    if (isShowReports) {
        skeletonColumnCount += 1
    }

    const getTableRow = (agendaItem: ApplicationAgendaItemInterface) => {
        const inner = (
            <>
                <TableCell sx={{py: 0.25}}>
                    {agendaItem.contact_description ? (
                        <HtmlTooltip title={agendaItem.contact_description}>
                            <Box>{agendaItem.client_description}</Box>
                        </HtmlTooltip>
                    ) : (
                        agendaItem.client_description
                    )}
                </TableCell>
                <TableCell sx={{py: 0.25}}>
                    {agendaItem.description ? (
                        <HtmlTooltip title={agendaItem.description}>{getAgendaItemDetails(agendaItem)}</HtmlTooltip>
                    ) : (
                        getAgendaItemDetails(agendaItem)
                    )}
                </TableCell>
                {isShowReports && (
                    <TableCell sx={{py: 0.25}}>{agendaItem.assigned_to_application_user_description}</TableCell>
                )}
                {isOverdue && (
                    <TableCell sx={{py: 0.25}}>{agendaItem.due_date ? formatDate(agendaItem.due_date) : '-'}</TableCell>
                )}
            </>
        )
        if (isUseLeadTaskQuickActionModal && agendaItem.lead_id && agendaItem.task_id) {
            return (
                <TableRow key={agendaItem.key} hover sx={{cursor: 'pointer'}} onClick={() => handleClick(agendaItem)}>
                    {inner}
                </TableRow>
            )
        } else {
            return (
                <TableRow
                    key={agendaItem.key}
                    hover
                    sx={{cursor: 'pointer'}}
                    onClick={() => handleOpenLinkInNewTab(agendaItem.milan_path)}
                >
                    {inner}
                </TableRow>
            )
        }
    }

    const nextDayString = formatDate(addBusinessDays(agendaDate, 1), 'EEEE')

    const getExtraTableRow = () => {
        if (countTomorrowAgendaItems) {
            const tomorrowText = getDueTomorrowText(
                countTomorrowAgendaItems,
                countTomorrowCallbackAgendaItems,
                nextDayString,
            )
            if (shownAgendaItems.length == 0) {
                return (
                    <>
                        <TableRow>
                            <TableCell colSpan={skeletonColumnCount} align={'center'}>
                                <Typography variant="body2">No Agenda Items</Typography>
                                <Typography variant="body2" sx={{fontStyle: 'oblique', mt: 1}}>
                                    {tomorrowText}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </>
                )
            } else {
                return (
                    <>
                        <TableRow>
                            <TableCell sx={{fontStyle: 'oblique', py: 0.25}}>{`${nextDayString}'s agenda`}</TableCell>
                            <TableCell colSpan={skeletonColumnCount - 1} sx={{fontStyle: 'oblique', py: 0.25}}>
                                {tomorrowText}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={skeletonColumnCount} sx={{py: 1}} />
                        </TableRow>
                    </>
                )
            }
        } else {
            if (shownAgendaItems.length == 0) {
                return (
                    <TableRow>
                        <TableCell colSpan={skeletonColumnCount} align={'center'}>
                            No Agenda Items
                        </TableCell>
                    </TableRow>
                )
            } else {
                return (
                    <TableRow>
                        <TableCell colSpan={skeletonColumnCount} sx={{py: 1}} />
                    </TableRow>
                )
            }
        }
    }

    return (
        <Card
            sx={{
                height: disableDynamicHeight ? 'auto' : '100%',
                display: disableDynamicHeight ? undefined : 'flex',
                flexDirection: disableDynamicHeight ? undefined : 'column',
            }}
        >
            <CardHeader
                title={
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography
                                onClick={handleHeaderClick}
                                variant={boldTitle ? 'h6' : undefined}
                                sx={(theme) => ({cursor: 'pointer', '&:hover': {bgColor: theme.palette.action.hover}})}
                            >
                                {title}
                            </Typography>
                            {showIncludeReports && (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={isShowReports}
                                            size={narrowHeader ? 'small' : 'medium'}
                                            edge="start"
                                            name="direction"
                                            onChange={(event) => {
                                                setIsShowReports(event.target.checked)
                                            }}
                                            sx={{ml: 1}}
                                        />
                                    }
                                    label={`Show reports${reportsItemsCount ? ` (+${reportsItemsCount})` : ''}`}
                                    labelPlacement="start"
                                />
                            )}
                        </Box>
                        {showAgendaLeadChips && setSelectedAgendaLeadTypeId && (
                            <Box>
                                {Array.from(AgendaLeadTypeMap.entries())
                                    .reverse()
                                    .map((value) => (
                                        <Chip
                                            key={value[0]}
                                            sx={{mr: 1}}
                                            size="medium"
                                            label={value[1]}
                                            color={selectedAgendaLeadTypeId == value[0] ? 'primary' : undefined}
                                            onClick={() => {
                                                if (selectedAgendaLeadTypeId == value[0]) {
                                                    setSelectedAgendaLeadTypeId(undefined)
                                                } else {
                                                    setSelectedAgendaLeadTypeId(value[0])
                                                }
                                            }}
                                        />
                                    ))}
                            </Box>
                        )}
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            {extraAgendaItems.length > 0 && (
                                <Button
                                    sx={{mr: 2}}
                                    size={narrowHeader ? 'small' : 'medium'}
                                    variant="contained"
                                    onClick={() => setIsModalOpen(true)}
                                >
                                    {`+ ${extraAgendaItems.length} more`}
                                </Button>
                            )}
                            {showDatePicker && (
                                <Box sx={{display: 'flex', alignItems: 'center'}}>
                                    <Tooltip title={`View ${addBusinessDays(agendaDate, -1).toDateString()} agenda`}>
                                        <Box>
                                            <IconButton
                                                size={'small'}
                                                onClick={() =>
                                                    handleAgendaDateChange(addBusinessDays(new Date(agendaDate), -1))
                                                }
                                                disabled={
                                                    new Date(agendaDate).setHours(0, 0, 0, 0) ==
                                                    new Date().setHours(0, 0, 0, 0)
                                                }
                                            >
                                                <ChevronLeftIcon />
                                            </IconButton>
                                        </Box>
                                    </Tooltip>
                                    <Tooltip title={`View ${addBusinessDays(agendaDate, 1).toDateString()} agenda`}>
                                        <Box>
                                            <IconButton
                                                size={'small'}
                                                onClick={() =>
                                                    handleAgendaDateChange(addBusinessDays(new Date(agendaDate), 1))
                                                }
                                                sx={{mr: 1}}
                                            >
                                                <ChevronRightIcon />
                                            </IconButton>
                                        </Box>
                                    </Tooltip>
                                    <Box sx={{display: 'flex', width: 150}}>
                                        <DatePicker
                                            openTo="day"
                                            label="Agenda Date"
                                            minDate={new Date(2020, 1, 1)}
                                            value={agendaDate}
                                            onChange={(date) => handleAgendaDateChange(date)}
                                            format="dd/MM/yyyy"
                                            slotProps={{textField: {size: narrowHeader ? 'small' : 'medium'}}}
                                        />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                }
                avatar={
                    <GenevaIconAvatar
                        color={hasOverdueTasks() ? 'warning' : 'success'}
                        height={narrowHeader ? 32 : 42}
                        width={narrowHeader ? 32 : 42}
                    >
                        <FormatListBulletedIcon fontSize={narrowHeader ? 'small' : 'medium'} />
                    </GenevaIconAvatar>
                }
                sx={{py: narrowHeader ? 1 : 2}}
                titleTypographyProps={{variant: 'body1'}}
            />
            <Box sx={{display: 'flex', flexGrow: 1}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{py: 0.25}}>{'Client'}</TableCell>
                            <TableCell sx={{py: 0.25}}>{'Details'}</TableCell>
                            {isShowReports && <TableCell sx={{py: 0.25, width: '25%'}}>{'Assigned To'}</TableCell>}
                            {isOverdue && <TableCell sx={{py: 0.25, width: '20%'}}>{'Due Date'}</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading
                            ? skeletonRows(5, skeletonColumnCount)
                            : shownAgendaItems.length > 0
                              ? shownAgendaItems.map((agendaItem, index) => {
                                    if (index + 1 == shownAgendaItems.length) {
                                        return (
                                            <Fragment key={agendaItem.key}>
                                                {getTableRow(agendaItem)}
                                                {getExtraTableRow()}
                                            </Fragment>
                                        )
                                    } else {
                                        return getTableRow(agendaItem)
                                    }
                                })
                              : getExtraTableRow()}
                    </TableBody>
                </Table>
            </Box>
            {isModalOpen && (
                <UserAgendaItemsModal
                    agendaItems={extraAgendaItems}
                    isShowReports={isShowReports}
                    isOverdue={isOverdue}
                    onClose={() => setIsModalOpen(false)}
                    open={isModalOpen}
                />
            )}
            {isLeadTaskQuickActionModalOpen && selectedLeadInformation && (
                <LeadTaskQuickActionModal
                    lead_id={selectedLeadInformation.leadId}
                    task_id={selectedLeadInformation.taskId}
                    isLeadTask={true}
                    onComplete={handleComplete}
                    onClose={() => setIsLeadTaskQuickActionModalOpen(false)}
                    open={isLeadTaskQuickActionModalOpen}
                />
            )}
            {isTaskCreateModalOpen && selectedLeadInformation && (
                <LeadTaskCreateModal
                    lead_id={selectedLeadInformation.leadId}
                    lu_lead_sub_task_type_id={luLeadSubTaskTypeId}
                    due_date={dueDate}
                    onSave={handleTaskComplete}
                    onClose={() => setIsTaskCreateModalOpen(false)}
                    open={isTaskCreateModalOpen}
                />
            )}
        </Card>
    )
}

export default UserAgendaCard
