import {FC, ReactNode} from 'react'
import {Avatar} from '@mui/material'
import {alpha} from '@mui/material/styles'

interface Props {
    children: ReactNode
    color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined
    colorOverride?: string
    height?: number
    width?: number
    onClick?: () => void
}

const GenevaIconAvatar: FC<Props> = ({
    children,
    color = 'success',
    colorOverride,
    height = 42,
    width = 42,
    onClick,
}) => {
    return (
        <Avatar
            aria-label="Geneva Icon"
            onClick={onClick}
            sx={(theme) => ({
                height: height,
                width: width,
                backgroundColor: alpha(colorOverride ? colorOverride : theme.palette[color]['main'], 0.08),
                color: colorOverride ? colorOverride : theme.palette[color]['main'],
                cursor: onClick ? 'pointer' : 'default',
            })}
        >
            {children}
        </Avatar>
    )
}

export default GenevaIconAvatar
