import authAxios from './authAxios'
import {
    LeadApplicationUserTypeEnum,
    LeadCloseControllerInterface,
    LeadCloseReviewControllerInterface,
    LeadConcernInterface,
    LeadDetailsControllerInterface,
    LeadEntryMatchInterface,
    LeadGoalInterface,
    LeadInclusionsControllerInterface,
    LeadInterface,
    LeadInterimReassignmentInterface,
    LeadITCFInformationControllerInterface,
    LeadNurtureDebugInterface,
    LeadNurtureFinishControllerInterface,
    LeadPackageControllerInterface,
    LeadPersonInterface,
    LeadQualificationControllerInterface,
    LeadReopenControllerInterface,
    LeadServiceInterface,
    LeadServiceReducedInterface,
    LeadSwitcherNoteInterface,
    LeadTeamControllerInterface,
    LeadWonControllerInterface,
    NewLeadInterface,
} from '@/interfaces/lead_types'
import {InterimTeamControllerInterface} from '@/interfaces/controller_types'

interface NewLeadSaveResult {
    data: {
        message: string
        lead: LeadInterface
    }
    status: string
}

export async function saveNewLead(new_lead: NewLeadInterface): Promise<NewLeadSaveResult> {
    return authAxios.post(`/api/sales/new_lead`, new_lead)
}

interface LeadSaveResult {
    data: {
        message: string
        lead: LeadInterface
    }
    status: string
}

export async function saveLead(lead: LeadInterface): Promise<LeadSaveResult> {
    return authAxios.post(`/api/leads`, lead)
}

export async function getLead(lead_id: number): Promise<LeadSaveResult> {
    return authAxios.get(`/api/leads/${lead_id}`)
}

interface LeadEntryMatchResult {
    data: {
        leads: LeadInterface[]
    }
}

export async function getLeadEntryMatch(lead_entry_match: LeadEntryMatchInterface): Promise<LeadEntryMatchResult> {
    return authAxios.post('/api/lead_entry_match', lead_entry_match)
}

interface LeadPersonSaveResult {
    data: {
        message: string
        lead_person: LeadPersonInterface
    }
    status: string
}

export async function saveLeadPerson(lead_person: LeadPersonInterface): Promise<LeadPersonSaveResult> {
    return authAxios.post(`/api/lead_persons`, lead_person)
}

export async function setLeadWithIdToInProgress(lead_id: number): Promise<LeadSaveResult> {
    return authAxios.post(`/api/leads/${lead_id}/set_to_in_progress`)
}

export async function saveLeadQualificationController(
    lead_qualification_controller: LeadQualificationControllerInterface,
): Promise<LeadSaveResult> {
    return authAxios.post(`/api/leads/lead_qualification_controller`, lead_qualification_controller)
}

export async function setLeadWithIdToClosed(lead_id: number): Promise<LeadSaveResult> {
    return authAxios.post(`/api/leads/${lead_id}/set_to_closed`)
}

export async function saveLeadDetails(
    lead_details_controller: LeadDetailsControllerInterface,
): Promise<LeadSaveResult> {
    return authAxios.post(`/api/leads/lead_details`, lead_details_controller)
}

interface LeadServiceResult {
    data: {
        message: string
        lead_service: LeadServiceInterface
    }
    status: string
}

export async function saveServiceForLeadId(
    lead_id: number,
    lead_service: LeadServiceReducedInterface,
): Promise<LeadServiceResult> {
    return await authAxios.post(`/api/leads/${lead_id}/services/${lead_service.service_id}`, lead_service)
}

interface LeadConcernResult {
    message: string
    lead_concern: LeadConcernInterface
}

export async function saveLeadConcern(lead_concern: LeadConcernInterface) {
    return await authAxios.post<LeadConcernResult>(`/api/leads/lead_concerns`, lead_concern)
}

interface NewLeadConcernResult {
    lead_concern: LeadConcernInterface
}

export async function getNewLeadConcern(lead_id: number) {
    return await authAxios.get<NewLeadConcernResult>(`/api/leads/${lead_id}/new_lead_concern`)
}

interface LeadGoalResult {
    message: string
    lead_goal: LeadGoalInterface
}

export async function saveLeadGoal(lead_goal: LeadGoalInterface) {
    return await authAxios.post<LeadGoalResult>(`/api/leads/lead_goals`, lead_goal)
}

interface NewLeadGoalResult {
    lead_goal: LeadGoalInterface
}

export async function getNewLeadGoal(lead_id: number) {
    return await authAxios.get<NewLeadGoalResult>(`/api/leads/${lead_id}/new_lead_goal`)
}

interface LeadSwitcherNoteResult {
    data: {
        message: string
        lead_switcher_note: LeadSwitcherNoteInterface
    }
}

export async function saveLeadSwitcherNote(lead_switcher_note: LeadSwitcherNoteInterface) {
    return await authAxios.post<LeadSwitcherNoteResult>(`/api/leads/lead_switcher_notes`, lead_switcher_note)
}

interface NewLeadConcernResult {
    lead_switcher_note: LeadSwitcherNoteInterface
}

export async function getNewLeadSwitcherNote(lead_id: number) {
    return await authAxios.get<NewLeadConcernResult>(`/api/leads/${lead_id}/new_lead_switcher_note`)
}

export async function saveLeadClose(lead_close_controller: LeadCloseControllerInterface): Promise<LeadSaveResult> {
    return authAxios.post(`/api/leads/lead_close`, lead_close_controller)
}

export async function saveLeadCloseReview(
    lead_close_review_controller: LeadCloseReviewControllerInterface,
): Promise<LeadSaveResult> {
    return authAxios.post(`/api/leads/lead_close_review`, lead_close_review_controller)
}

export async function saveLeadWon(lead_won_controller: LeadWonControllerInterface): Promise<LeadSaveResult> {
    return authAxios.post(`/api/leads/lead_won`, lead_won_controller)
}

export async function saveLeadReopen(lead_reopen_controller: LeadReopenControllerInterface): Promise<LeadSaveResult> {
    return authAxios.post(`/api/leads/lead_reopen`, lead_reopen_controller)
}

export async function saveLeadTeam(lead_team_controller: LeadTeamControllerInterface): Promise<LeadSaveResult> {
    return authAxios.post(`/api/leads/lead_team`, lead_team_controller)
}

export async function makeLeadInterimUserRegularUser(
    leadApplicationUserTypeId: LeadApplicationUserTypeEnum,
    controller: LeadTeamControllerInterface,
) {
    return authAxios.post(
        `/api/leads/make_interim_user_regular_user?lu_lead_application_user_type_id=${leadApplicationUserTypeId}`,
        controller,
    )
}

export async function saveLeadPackage(
    lead_package_controller: LeadPackageControllerInterface,
): Promise<LeadSaveResult> {
    return authAxios.post(`/api/leads/lead_package`, lead_package_controller)
}

export async function leadPackageAcceptReferralCode(
    lead_package_controller: LeadPackageControllerInterface,
): Promise<LeadSaveResult> {
    return authAxios.post(`/api/leads/lead_package/accept_referral_code`, lead_package_controller)
}

export async function leadPackageWithheldReferralCode(
    lead_package_controller: LeadPackageControllerInterface,
): Promise<LeadSaveResult> {
    return authAxios.post(`/api/leads/lead_package/withheld_referral_code`, lead_package_controller)
}

export async function saveLeadInclusions(
    lead_inclusions_controller: LeadInclusionsControllerInterface,
): Promise<LeadSaveResult> {
    return authAxios.post(`/api/leads/lead_inclusions`, lead_inclusions_controller)
}

export async function setLeadToClosedWon(lead_id: number): Promise<LeadSaveResult> {
    return authAxios.post(`/api/leads/${lead_id}/set_to_closed_won`)
}

export async function saveLeadNurtureFinish(
    lead_nurture_finish_controller: LeadNurtureFinishControllerInterface,
): Promise<LeadSaveResult> {
    return authAxios.post(`/api/leads/lead_nurture_finish`, lead_nurture_finish_controller)
}

interface LeadOpenTaskResult {
    data: {
        message: string
        count: number
    }
}

export async function countLeadOpenTasks(lead_id: number): Promise<LeadOpenTaskResult> {
    return authAxios.get(`/api/leads/${lead_id}/lead_tasks/count_open`)
}

export async function sendLeadITCFInformation(
    lead_itcf_information_controller: LeadITCFInformationControllerInterface,
): Promise<LeadSaveResult> {
    return authAxios.post(`/api/leads/itcf_information_controller/send`, lead_itcf_information_controller)
}

interface LeadContactedResult {
    data: {
        lead_contacted: boolean
    }
}

export async function getHasLeadBeenContactedSinceMeetingAppointment(
    meeting_appointment_id: number,
): Promise<LeadContactedResult> {
    return authAxios.get(`/api/leads/meeting_appointment/${meeting_appointment_id}/contacted_since`)
}

export async function reassignLeadHomeCareSpecialistTasks(
    lead_id: number,
    old_home_care_specialist_application_user_id: number,
) {
    return await authAxios.post(
        `/api/leads/${lead_id}/reassign_home_care_specialist_tasks?old_home_care_specialist_application_user_id=${old_home_care_specialist_application_user_id}`,
    )
}

interface LeadInterimReassignmentSaveResult {
    data: {
        lead_interim_reassignment: LeadInterimReassignmentInterface
    }
}

export async function saveLeadInterimReassignment(
    lead_interim_reassignment: LeadInterimReassignmentInterface,
): Promise<LeadInterimReassignmentSaveResult> {
    return await authAxios.post(`/api/leads/interim_reassignments`, lead_interim_reassignment)
}

export async function endLeadInterimReassignment(
    lead_interim_reassignment_id: number,
): Promise<LeadInterimReassignmentSaveResult> {
    return await authAxios.post(`/api/lead/interim_reassignments/${lead_interim_reassignment_id}/end`)
}

export async function saveLeadInterimTeamController(controller: InterimTeamControllerInterface) {
    return await authAxios.post(`/api/leads/interim_team_controller`, controller)
}

export async function saveBulkLeadInterimReassignments(lead_interim_reassignments: LeadInterimReassignmentInterface[]) {
    return await authAxios.post(`/api/leads/interim_reassignments/bulk_save`, lead_interim_reassignments)
}

export async function saveLeadNurtureDebug(lead: LeadNurtureDebugInterface): Promise<LeadSaveResult> {
    return authAxios.post('/api/leads/nurture_debug', lead)
}
