import {Skeleton, TableCell, TableRow} from '@mui/material'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }

    if (b[orderBy] > a[orderBy]) {
        return 1
    }

    return 0
}

export function getComparator<Key extends keyof any>(
    order: 'asc' | 'desc',
    orderBy: Key,
): (a: {[key in Key]: number | string}, b: {[key in Key]: number | string}) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

export const skeletonRows = (rows: number, cells: number, noCellPadding: boolean = false) => {
    const getTableCell = (key: string) => {
        if (noCellPadding) {
            return (
                <TableCell key={key} sx={{py: 0}}>
                    <Skeleton />
                </TableCell>
            )
        } else {
            return (
                <TableCell key={key}>
                    <Skeleton />
                </TableCell>
            )
        }
    }

    return Array(rows)
        .fill('')
        .map((_, rowIndex) => (
            <TableRow key={rowIndex}>
                {Array(cells)
                    .fill('')
                    .map((_, cellIndex) => getTableCell(`${rowIndex}_${cellIndex}`))}
            </TableRow>
        ))
}
