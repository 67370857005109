import {TaskPriorityEnum} from '@/interfaces/task_types'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {OverridableComponent} from '@mui/material/OverridableComponent'
import {SvgIconPropsColorOverrides, SvgIconTypeMap} from '@mui/material'
import {OverridableStringUnion} from '@mui/types'

export const getPriorityColor = (
    lu_task_priority_id: TaskPriorityEnum,
): OverridableStringUnion<
    'error' | 'warning' | 'info' | 'disabled' | 'action' | 'inherit' | 'primary' | 'secondary' | 'success',
    SvgIconPropsColorOverrides
> => {
    if (lu_task_priority_id == TaskPriorityEnum.VERY_HIGH) {
        return 'error'
    } else if (lu_task_priority_id == TaskPriorityEnum.HIGH) {
        return 'error'
    } else if (lu_task_priority_id == TaskPriorityEnum.MEDIUM) {
        return 'warning'
    } else {
        return 'info'
    }
}

export const getPriorityIcon = (
    lu_task_priority_id: TaskPriorityEnum,
): OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {muiName: string} => {
    if (lu_task_priority_id == TaskPriorityEnum.VERY_HIGH) {
        return KeyboardDoubleArrowUpIcon
    } else if (lu_task_priority_id == TaskPriorityEnum.HIGH) {
        return KeyboardArrowUpIcon
    } else if (lu_task_priority_id == TaskPriorityEnum.MEDIUM) {
        return DragHandleIcon
    } else {
        return KeyboardArrowDownIcon
    }
}

export const getPrioritySmallIcon = (lu_task_priority_id: TaskPriorityEnum, marginRight: number): JSX.Element => {
    const Icon = getPriorityIcon(lu_task_priority_id)
    const color = getPriorityColor(lu_task_priority_id)
    return <Icon color={color} sx={{mr: marginRight}} />
}
