import {FC, useState} from 'react'
import {Box} from '@mui/material'
import {ApplicationAgendaInterface, ApplicationAgendaItemInterface, GenevaUserInterface} from '@/interfaces/user_types'
import useGenevaFetch from '@/hooks/useGenevaFetch'
import ServerError from '@/views/error/ServerError'
import {formatDateAsISO} from '@/utils/formatter'
import UserAgendaCard from '@/components/user/UserAgendaCard'

interface Props {
    genevaUser: GenevaUserInterface
    boldTile?: boolean
    isUseLeadTaskQuickActionModal?: boolean
    allowIncludeReports?: boolean
    narrowHeader?: boolean
}

const filterAgendaItems = (
    applicationUserId: number,
    agendaItems: ApplicationAgendaItemInterface[],
    includeReports: boolean,
) => {
    if (!includeReports) {
        return agendaItems.filter((item) => {
            if (item.assigned_to_application_user_id == applicationUserId) {
                return item
            }
            if (item.is_overdue_activation) {
                return item
            }
        })
    } else {
        return agendaItems
    }
}

const UserAgenda: FC<Props> = ({
    genevaUser,
    boldTile = false,
    isUseLeadTaskQuickActionModal = false,
    allowIncludeReports = false,
    narrowHeader = false,
}) => {
    const [agendaDate, setAgendaDate] = useState<Date>(new Date())
    const [myAgendaIsShowReports, setMyAgendaIsShowReports] = useState<boolean>(false)
    const [overdueAgendaIsShowReports, setOverdueAgendaIsShowReports] = useState<boolean>(false)

    const getUrl = (isOverdue: boolean) => {
        let url = `/api/users/${genevaUser.id}`
        if (isOverdue) {
            url += '/overdue_agenda'
        } else {
            url += '/agenda'
            url += `?from_date=${formatDateAsISO(agendaDate)}`
        }
        return url
    }

    const agendaSync = useGenevaFetch(getUrl(false))
    const overdueAgendaSync = useGenevaFetch(getUrl(true))

    if (agendaSync.error || overdueAgendaSync.error) {
        return <ServerError />
    }

    const agenda =
        !agendaSync.isLoading && agendaSync.data ? (agendaSync.data.agenda as ApplicationAgendaInterface) : null
    const overdueAgenda =
        !overdueAgendaSync.isLoading && overdueAgendaSync.data
            ? (overdueAgendaSync.data.overdue_agenda as ApplicationAgendaInterface)
            : null
    const showIncludeReports = allowIncludeReports && genevaUser.has_subordinates

    const myAgendaItems = agenda ? filterAgendaItems(genevaUser.id, agenda.agenda_items, myAgendaIsShowReports) : []
    const myAgendaReportsItemsCount =
        agenda && !myAgendaIsShowReports ? agenda.agenda_items.length - myAgendaItems.length : null
    const overdueAgendaItems = overdueAgenda
        ? filterAgendaItems(genevaUser.id, overdueAgenda.agenda_items, overdueAgendaIsShowReports)
        : []
    const overdueAgendaReportsItemsCount =
        overdueAgenda && !overdueAgendaIsShowReports
            ? overdueAgenda.agenda_items.length - overdueAgendaItems.length
            : null

    return (
        <Box sx={{display: 'flex'}}>
            <Box sx={{width: '50%', mr: 1}}>
                <UserAgendaCard
                    title={'My Agenda'}
                    boldTitle={boldTile}
                    agendaItems={myAgendaItems}
                    agendaItemLimit={10}
                    reportsItemsCount={myAgendaReportsItemsCount}
                    countTomorrowAgendaItems={agenda ? agenda.count_tomorrow_agenda_items : null}
                    countTomorrowCallbackAgendaItems={agenda ? agenda.count_tomorrow_call_back_agenda_items : null}
                    agendaDate={agendaDate}
                    setAgendaDate={setAgendaDate}
                    isShowReports={myAgendaIsShowReports}
                    setIsShowReports={setMyAgendaIsShowReports}
                    showDatePicker={true}
                    isOverdue={false}
                    isLoading={agendaSync.isLoading}
                    isUseLeadTaskQuickActionModal={isUseLeadTaskQuickActionModal}
                    showIncludeReports={showIncludeReports}
                    narrowHeader={narrowHeader}
                />
            </Box>
            <Box sx={{width: '50%', ml: 1}}>
                <UserAgendaCard
                    title={'Overdue'}
                    boldTitle={boldTile}
                    agendaItems={overdueAgendaItems}
                    agendaItemLimit={10}
                    reportsItemsCount={overdueAgendaReportsItemsCount}
                    countTomorrowAgendaItems={null}
                    countTomorrowCallbackAgendaItems={null}
                    agendaDate={agendaDate}
                    setAgendaDate={setAgendaDate}
                    isShowReports={overdueAgendaIsShowReports}
                    setIsShowReports={setOverdueAgendaIsShowReports}
                    showDatePicker={false}
                    isOverdue={true}
                    isLoading={overdueAgendaSync.isLoading}
                    isUseLeadTaskQuickActionModal={isUseLeadTaskQuickActionModal}
                    showIncludeReports={showIncludeReports}
                    narrowHeader={narrowHeader}
                />
            </Box>
        </Box>
    )
}

export default UserAgenda
