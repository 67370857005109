import {experimentalStyled as styled} from '@mui/material/styles'
import Tooltip, {tooltipClasses, TooltipProps} from '@mui/material/Tooltip'

export const HtmlTooltip = styled(
    ({className, ...props}: TooltipProps) => <Tooltip {...props} classes={{popper: className}} />,
    {},
)(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        fontSize: theme.typography.pxToRem(12),
        border: `1px solid ${theme.palette.background.default}`,
        whiteSpace: 'pre-line',
    },
}))
