import {HistoryEntityInterface} from '@/interfaces/entity_types'
import {LeadInterface} from '@/interfaces/lead_types'
import {GenevaUserInterface} from './user_types'

export const enum TaskCategoryEnum {
    CLIENT = 1,
    WORKER = 2,
    USER = 3,
    FINANCE = 4,
    SUPPLIER = 5,
    LEAD = 6,
    SUPPLIER_LEAD = 7,
}

export const enum TaskPriorityEnum {
    LOW = 1,
    MEDIUM = 2,
    HIGH = 3,
    VERY_HIGH = 4,
}

export const enum TaskStatusEnum {
    LU_TASK_STATUS_NEW = 1,
    LU_TASK_STATUS_PAUSED = 2,
    LU_TASK_STATUS_CANCELLED = 3,
    LU_TASK_STATUS_COMPLETED = 4,
}

export interface TaskContentInterface {
    entity_type: string
    person_id?: number
    address_id?: number

    [name: string]: unknown
}

export type TaskStatusType = 'New' | 'Assigned' | 'Paused' | 'Cancelled' | 'Completed'

export type TaskPriorityType = 'Essential' | 'High' | 'Medium' | 'Low'

export interface TaskInterface extends HistoryEntityInterface {
    [key: string]: any

    person_id: number
    lead_id: number
    supplier_id: number
    supplier_lead_id: number
    parent_task_id: number
    assigned_to_application_user_id: number
    assigned_to_application_user_description: string
    due_date: Date
    due_time: string
    due_date_and_time: Date
    due_in_days_from_today: number
    due_in_days_from_today_description: string
    lu_task_category_id: number
    task_category_description: string
    lu_task_priority_id: number
    task_priority_description: TaskPriorityType
    lu_task_status_id: number
    task_status_description: TaskStatusType
    task_object_description: string
    subject: string
    description: string
    result: string
    person_description: string

    task_type_description: string
    task_sub_type_description: string

    milan_path: string

    is_client_task: boolean
    is_finance_task: boolean
    is_supplier_task: boolean
    is_lead_task: boolean
    is_supplier_lead_task: boolean

    is_new_status: boolean
    is_open: boolean
    is_closed: boolean
    is_overdue: boolean
    is_paused: boolean
    is_cancelled: boolean
    is_completed: boolean

    can_be_paused: boolean
    can_be_set_to_new: boolean
    can_be_set_to_completed: boolean
    can_be_set_to_cancelled: boolean

    is_essential_priority: boolean
    is_high_priority: boolean
    is_medium_priority: boolean
    is_low_priority: boolean

    paused_on: Date
    completed_on: Date
    cancelled_on: Date
    assigned_on: Date
    paused_by_application_user_description: string
    completed_by_application_user_description: string
    cancelled_by_application_user_description: string
    paused_by_application_user_id: number
    completed_by_application_user_id: number
    cancelled_by_application_user_id: number

    is_visual_effect_on_completion_for_user: boolean
    can_only_be_completed_by_assigned_user: boolean

    content: TaskContentInterface
}

export interface TaskActionInterface extends HistoryEntityInterface {
    id: number
    lu_task_action_status_id: number
    actioned_on: Date

    actioned_by_application_user_id: number

    stockholm_api_url: string
    milan_button_text: string
    milan_confirm_message: string
    description: string
    requires_comment: boolean

    is_cancellation_action: boolean

    task_action_status_description: string

    actioned_by_application_user_description: string

    is_actioned: boolean
    is_actioned_completes_task: boolean
    is_actioned_cancels_task: boolean
    is_actioned_pauses_task: boolean
    is_actioned_ignores_task: boolean

    is_client_task_action: boolean
    is_supplier_task_action: boolean
    is_lead_task_action: boolean
}

export interface ClientTaskActionInterface extends TaskActionInterface {
    client_task_id: number
}

export interface ClientTaskInterface extends TaskInterface {
    person_id: number
    appointment_id: number
    recurrence_rule_id: number
    assessment_id: number
    incident_id: number
    feedback_id: number
    equipment_item_id: number
    subscription_item_id: number
    meeting_appointment_id: number
    contract_offboarding_id: number
    twilio_task_sid: string
    lu_client_task_type_id: ClientTaskTypeEnum
    client_task_type_description: string
    lu_client_sub_task_type_id: number
    client_sub_task_type_description: string
    person_first_last_name: string
    supplier_name: string
    person_description: string
    paused_on: Date
    completed_on: Date
    cancelled_on: Date
    paused_by_application_user_description: string
    completed_by_application_user_description: string
    cancelled_by_application_user_description: string
    paused_by_application_user_id: number
    completed_by_application_user_id: number
    cancelled_by_application_user_id: number

    client_task_actions: ClientTaskActionInterface[]

    service_description: string
    supplier_description: string
    worker_description: string
    worker_first_last_name: string
    appointment_date: string

    has_appointment_cancellation_reason_requirement: boolean

    is_appointment_task: boolean
    is_appointment_series_task: boolean
    is_leave_task: boolean
    is_initial_care_manager_assessment_review_task: boolean
    is_assessment_check_in_follow_up_task: boolean
    is_client_call_back_task: boolean
    is_proposed_recurrence_rule_client_task: boolean
    is_booking_requested_recurrence_rule_client_task: boolean
    is_cancellation_requested_recurrence_rule_client_task: boolean
    is_reschedule_requested_recurrence_rule_client_task: boolean

    incident_description: string
    feedback_description: string

    client_task_to_do_items: ClientTaskToDoItemInterface[]
}

export interface ClientTaskToDoItemInterface {
    id: number

    client_task_id: number
    details: string
    is_complete: boolean
}

export interface SupplierTaskActionInterface extends TaskActionInterface {
    supplier_task_id: number
}

export const enum SupplierTaskTypeEnum {
    LU_SUPPLIER_TASK_TYPE_SETUP = 1,
    LU_SUPPLIER_TASK_TYPE_NOTE = 2,
    LU_SUPPLIER_TASK_TYPE_FEEDBACK = 3,
}

export const enum SupplierSubTaskTypeEnum {
    LU_SUPPLIER_SUB_TASK_TYPE_FEEDBACK_SUGGESTION_FOLLOW_UP = 300,
    LU_SUPPLIER_SUB_TASK_TYPE_FEEDBACK_COMPLAINT_FOLLOW_UP = 302,
    LU_SUPPLIER_SUB_TASK_TYPE_FEEDBACK_COMPLIMENT_FOLLOW_UP = 303,
}

export interface SupplierTaskInterface extends TaskInterface {
    supplier_id: number
    lu_supplier_task_type_id: SupplierTaskTypeEnum
    supplier_task_type_description: string
    lu_supplier_sub_task_type_id: number
    feedback_id: number
    twilio_task_sid: string
    supplier_sub_task_type_description: string
    supplier_description: string

    supplier_task_actions: SupplierTaskActionInterface[]
}

export interface SupplierLeadTaskInterface extends TaskInterface {
    supplier_lead_id: number
    lu_supplier_lead_task_type_id: number
    supplier_lead_task_type_description: string
    lu_supplier_lead_sub_task_type_id: number
    supplier_lead_sub_task_type_description: string
    supplier_lead_description: string
}

export const enum ClientTaskTypeEnum {
    LU_CLIENT_TASK_TYPE_CLIENT = 1,
    LU_CLIENT_TASK_TYPE_ADDRESS = 2,
    LU_CLIENT_TASK_TYPE_RELATIONSHIP = 3,
    LU_CLIENT_TASK_TYPE_REPRESENTATIVE = 4,
    LU_CLIENT_TASK_TYPE_ASSESSMENT = 5,
    LU_CLIENT_TASK_TYPE_APPOINTMENT = 6,
    LU_CLIENT_TASK_TYPE_FEEDBACK = 7,
    LU_CLIENT_TASK_TYPE_INCIDENT = 8,
    LU_CLIENT_TASK_TYPE_FINANCE = 9,
    LU_CLIENT_TASK_TYPE_LEAVE = 10,
    LU_CLIENT_TASK_TYPE_APPOINTMENT_SERIES = 11,
    LU_CLIENT_TASK_TYPE_CARE_PLAN = 12,
    LU_CLIENT_TASK_TYPE_NOTE = 13,
    LU_CLIENT_TASK_TYPE_EQUIPMENT = 14,
    LU_CLIENT_TASK_TYPE_OFFBOARDING = 15,
    LU_CLIENT_TASK_TYPE_LEAD = 16,
    LU_CLIENT_TASK_TYPE_SUBSCRIPTION = 17,
    LU_CLIENT_TASK_TYPE_MEETING_APPOINTMENT = 18,
}

export const enum ClientSubTaskTypeEnum {
    LU_CLIENT_SUB_TASK_TYPE_CLIENT_GENERAL = 100,
    LU_CLIENT_SUB_TASK_TYPE_CLIENT_MAC_REFERRAL = 112,
    LU_CLIENT_SUB_TASK_TYPE_CLIENT_MAC_SERVICE_INFORMATION = 113,
    LU_CLIENT_SUB_TASK_TYPE_FOLLOW_UP_TASK = 120,
    LU_CLIENT_SUB_TASK_TYPE_CALL_BACK = 130,
    LU_CLIENT_SUB_TASK_TYPE_ASSESSMENT_CHECK_IN_FOLLOW_UP = 502,
    LU_CLIENT_SUB_TASK_TYPE_APPOINTMENT_GENERAL = 600,
    LU_CLIENT_SUB_TASK_TYPE_FEEDBACK_SUGGESTION_FOLLOW_UP = 700,
    LU_CLIENT_SUB_TASK_TYPE_FEEDBACK_COMPLIMENT_FOLLOW_UP = 701,
    LU_CLIENT_SUB_TASK_TYPE_FEEDBACK_COMPLAINT_FOLLOW_UP = 702,
    LU_CLIENT_SUB_TASK_TYPE_INCIDENT_GENERAL = 800,
    LU_CLIENT_SUB_TASK_TYPE_INCIDENT_WORKER_REPORT = 803,
    LU_CLIENT_SUB_TASK_TYPE_INCIDENT_FALL = 801,
    LU_CLIENT_SUB_TASK_TYPE_INCIDENT_VISITATION_HAZARD = 802,
    LU_CLIENT_SUB_TASK_TYPE_INCIDENT_NEAR_MISS = 805,
    LU_CLIENT_SUB_TASK_TYPE_FINANCE_ACER_ENTRY = 902,
    LU_CLIENT_SUB_TASK_TYPE_APPOINTMENT_SERIES_PROPOSED = 1100,
    LU_CLIENT_SUB_TASK_TYPE_APPOINTMENT_SERIES_BOOKING_REQUEST = 1101,
    LU_CLIENT_SUB_TASK_TYPE_EQUIPMENT_GENERAL = 1400,
    LU_CLIENT_SUB_TASK_TYPE_OFFBOARDING_GENERAL = 1500,
    LU_CLIENT_SUB_TASK_TYPE_OFFBOARDING_CANCEL_SERVICES = 1510,
    LU_CLIENT_SUB_TASK_TYPE_OFFBOARDING_CANCEL_SUPPLIER_SERVICES = 1511,
    LU_CLIENT_SUB_TASK_TYPE_SUBSCRIPTION_GENERAL = 1700,
    LU_CLIENT_SUB_TASK_TYPE_MEETING_APPOINTMENT_GENERAL = 1800,
    LU_CLIENT_SUB_TASK_TYPE_MEETING_APPOINTMENT_ICMA_REVIEW = 1810,
    LU_CLIENT_SUB_TASK_TYPE_BOOKING_GENERAL_BOOKING = 1900,
    LU_CLIENT_SUB_TASK_TYPE_BOOKING_PORTAL_CLIENT_BOOKING_NOTIFICATION = 1910,
}

export const TwilioClientSubTaskType = [
    ClientSubTaskTypeEnum.LU_CLIENT_SUB_TASK_TYPE_FOLLOW_UP_TASK,
    ClientSubTaskTypeEnum.LU_CLIENT_SUB_TASK_TYPE_CALL_BACK,
    ClientSubTaskTypeEnum.LU_CLIENT_SUB_TASK_TYPE_APPOINTMENT_GENERAL,
]

export interface LeadTaskInterface extends TaskInterface {
    lead_id: number
    lead: LeadInterface
    lead_note_id: number

    meeting_appointment_id: number
    twilio_task_sid: string

    is_contact_relationship: boolean
    relationship_person_id: number

    lu_lead_task_type_id: number
    lead_task_type_description: string

    lu_lead_sub_task_type_id: number
    lead_sub_task_type_description: string

    lu_lead_task_reason_id: number
    lead_task_reason_description: string

    paused_by_application_user_description: string
    completed_by_application_user_description: string
    cancelled_by_application_user_description: string
    relationship_person_description: string
    contact_relationship_description: string

    is_close_review_lead_task: boolean
    is_initial_care_manager_assessment_review_task: boolean

    lead_task_to_do_items: LeadTaskToDoItemInterface[]
}

export interface LeadTaskToDoItemInterface {
    id: number

    lead_task_id: number
    details: string
    is_complete: boolean
}

export const enum LeadTaskTypeEnum {
    LU_LEAD_TASK_TYPE_SALES = 1,
}

export const enum LeadSubTaskTypeEnum {
    LU_LEAD_SUB_TASK_TYPE_SALES_CALL_FOLLOW_UP = 100,
    LU_LEAD_SUB_TASK_TYPE_SALES_EMAIL_FOLLOW_UP = 101,
    LU_LEAD_SUB_TASK_TYPE_SALES_ADMIN = 102,
    LU_LEAD_SUB_TASK_TYPE_SALES_MAC_PROCESS = 103,
}

export const enum TaskEventTypeEnum {
    LU_TASK_EVENT_TYPE_CREATE = 1,
    LU_TASK_EVENT_TYPE_ASSIGN = 2,
    LU_TASK_EVENT_TYPE_PRIORITY_INCREASE = 3,
    LU_TASK_EVENT_TYPE_PRIORITY_DECREASE = 4,
    LU_TASK_EVENT_TYPE_STATUS_PAUSED = 5,
    LU_TASK_EVENT_TYPE_STATUS_CANCELLED = 6,
    LU_TASK_EVENT_TYPE_STATUS_COMPLETED = 7,
    LU_TASK_EVENT_TYPE_DUE_DATE_ADDED = 8,
    LU_TASK_EVENT_TYPE_DUE_DATE_EXTENDED = 9,
    LU_TASK_EVENT_TYPE_DUE_DATE_EXPEDITED = 10,
    LU_TASK_EVENT_TYPE_DUE_DATE_REMOVED = 11,
    LU_TASK_EVENT_TYPE_TYPE_CHANGE = 12,
    LU_TASK_EVENT_TYPE_NOTE = 13,
}

export interface TaskManagementReportLineInterface {
    [key: string]: any

    id: number
    subject: string
    description: string
    created_by_application_user_id: number
    created_on: Date
    application_user_id: number
    updated_on: Date

    lu_task_category_id: number
    task_category_description: string
    lu_task_status_id: number
    task_status_description: string
    lu_task_priority_id: number
    task_priority_description: string

    is_new_status: boolean
    is_open: boolean
    is_closed: boolean
    is_completed: boolean
    is_cancelled: boolean
    is_paused: boolean
    is_overdue: boolean
    due_date_and_time?: Date

    is_client_task: boolean
    is_finance_task: boolean
    is_supplier_task: boolean
    is_supplier_lead_task: boolean
    is_lead_task: boolean

    person_id?: number
    supplier_id?: number
    supplier_lead_id?: number
    lead_id?: number

    created_by_application_user_description?: string
    application_user_description?: string
    due_date?: Date
    due_time?: Date
    assigned_to_application_user_id?: number
    assigned_to_application_user_description?: string
    paused_by_application_user_id?: number
    paused_on?: Date
    paused_by_application_user_description?: string
    cancelled_by_application_user_id?: number
    cancelled_on?: Date
    cancelled_by_application_user_description?: string
    completed_by_application_user_id?: number
    completed_on?: Date
    completed_by_application_user_description?: string
    assigned_to_reports_to_application_user_description?: string
}

export interface TaskManagementReportGrouping {
    [key: string]: any

    description: string
    provider_id: number
    start_date: Date
    end_date: Date
    task_ids: number[]
    count: number
    severity: SeverityEnum
}

export interface TaskManagementReportMonthInterface {
    [key: string]: any

    from_date: Date
    to_date: Date
    created_tasks_count: number
    completed_tasks_count: number
    overdue_at_end_of_month_count: number
    open_at_end_of_month_count: number
    created_task_ids: number[]
    completed_task_ids: number[]
    overdue_at_end_of_month_ids: number[]
    open_at_end_of_month_ids: number[]
    month_description: string
    year_description: string
}

export interface TaskManagementReportUserMonth {
    [key: string]: any

    application_user_id: number
    application_user: GenevaUserInterface
    report_months: TaskManagementReportMonthInterface[]
}

export interface TaskManagementReportUserTaskCount {
    application_user_id: number
    count: number
}

export interface TaskManagementReportSummaryInterface {
    [key: string]: any

    report_date: Date
    report_groupings: TaskManagementReportGrouping[]
    report_months: TaskManagementReportMonthInterface[]
    application_users: GenevaUserInterface[]
    report_months_per_user: TaskManagementReportUserMonth[]
    tasks: TaskManagementReportLineInterface[]
    overdue_tasks: TaskManagementReportLineInterface[]
    user_task_counts: TaskManagementReportUserTaskCount[]
}

export const enum SeverityEnum {
    GOOD = 1,
    MINOR = 2,
    MAJOR = 3,
    SEVERE = 4,
}
